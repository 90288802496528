import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { trigger, state, style, animate, transition } from '@angular/animations';
import {ActivatedRoute, Router} from "@angular/router";
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
	socialUser: SocialUser | undefined;
	isLoggedin = false;
	processing = false;
	user_email = '';
	logo = '';
	isPyrocast = true;
	constructor(fb: UntypedFormBuilder,
              private router: Router,
              private authService: AuthService,
							private _snackBar: MatSnackBar,
							private route: ActivatedRoute) {
		if(window.location.hostname == environment.skyDomain){
			this.logo = 'skystream_logo.png';
			this.isPyrocast = false;
		}
		if(window.location.hostname == environment.tune2Domain){
			this.logo = 'tune2-logo.webp';
			this.isPyrocast = false;
		}
		else{
			this.logo = 'pyrocast-logo-new.webp';
		}
		this.route.queryParams
			.subscribe(params => {
					if(params.email){
						this.user_email = params.email;
					}
				}
			);
	}

  ngOnInit(): void {
  }

	apple_login(){
  	this.authService.apple_login().then((data: any) => {
			if(data.authorization){
				this.processing = true;
				let params:any = {token: data.authorization.code, user_data: data.user, source: 'APPLE', origin: window.location.hostname};
				if(this.user_email){
					params.user_email = this.user_email;
				}
				this.authService.apiLogin(params).subscribe(response => {
					this.authService.login(response);
				});
			}
			else{
				this._snackBar.open('Error signing in with Apple. Try again', 'Close');
			}
		});
	}
	google_login(): void {
		this.authService.google_login().subscribe((user) => {
			this.processing = true;
			let params:any = {token: user.idToken, user_data: user, source: 'GOOGLE', origin: 'ISD'};
			if(this.user_email){
				params.user_email = this.user_email;
			}
			this.authService.apiLogin(params).subscribe(response => {
				this.authService.login(response);
			});
		}, error => {
			this._snackBar.open('Error signing in with Google. Try again', 'Close');
		});
	}

	logout(): void {
		this.authService.logout();
	}
}
