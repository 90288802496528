import {AlertService} from "../alert.service";

declare var AppleID: any;
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import {tap, delay, catchError, map} from 'rxjs/operators';
import { environment } from './../../../environments/environment';

import { User } from './../../interfaces/user.interface';
import {GoogleLoginProvider, SocialAuthService} from "angularx-social-login";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
	private readonly appleScriptUrl: string = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
	private ready: Promise<boolean> = new Promise(resolve => {
		if (typeof window !== 'undefined') {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = this.appleScriptUrl;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = (error: any) => resolve(false);
			document.getElementsByTagName('head')[0].appendChild(script);
		} else {
			resolve(false);
		}
	});
	isLoggedIn = new BehaviorSubject(false);
	isAdmin = new BehaviorSubject(false);
	token = new BehaviorSubject('');
	userSubject = new BehaviorSubject({});
	public user = this.userSubject.asObservable();
	// store the URL so we can redirect after logging in
	redirectUrl: string | null = null;
	apiUrl: string | null = null

  /**
   * Constructor
   * @param http The http client object
   */
  constructor(
    private http: HttpClient,
		private alertService: AlertService,
		private socialAuthService: SocialAuthService
  ) {
    this.apiUrl = environment.apiUrl;
		this.ready.then(isReady => {
			if (isReady) {
				this.init();
			}
		});
  }
	private init(): void {
		AppleID.auth.init({
			clientId: 'com.pyrocast.pyrocastservice',
			scope: 'name email',
			redirectURI: 'https://' + window.location.hostname + '/authenticationCallback.html',
			state: 'skyjams',
			usePopup: true,
		});
	}

	apple_login(): Promise<any>{
		return AppleID.auth.signIn();
		/*this.http.post(this.apiUrl + '/users/authenticate',{token: "cf58dc445baa7469791a90317c4b46429.0.ryyv.kEXLSvcDsqaG98W7Tv7LBA", user_data: null, source: 'APPLE', origin: 'ISD'}).subscribe((response:any) => {
			this.login(response);
		})
		return*/
		// AppleID.auth.signIn().then((data: any) => {
		// 	console.log('data: ', data)
		// 	if(data.authorization){
		// 		// this.http.post(this.apiUrl + '/users/authenticate',{token: data.authorization.code, user_data: data.user, source: 'APPLE', origin: 'AUDIO_APP_WEB'}).subscribe((response:any) => {
		// 			this.login(response);
		// 		})
		// 	}
		// });
	}

	google_login(): Observable<any>{
		this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
		return this.socialAuthService.authState;
	}

	apiLogin(data:any): Observable<any>{
		return this.http.post(this.apiUrl + '/users/authenticate',data);
	}

  login(response: any): void {
		if (response.error != undefined) {
			switch (response.error.title) {
				case "MULTIPLE_COBRA_IDS_FOUND":
					this.alertService.add({'type': 'error', 'message': 'Multiple Accounts use that Email Address.'});
					break;
				case "ERROR_SENDING_EMAIL":
					this.alertService.add({'type': 'error', 'message': 'There was an error while sending you the email/account confirmation email. Please contact us!'});
					break;
				default:
					this.alertService.add({'type': 'error', 'message': 'An Error Occurred During Login.'});
					break;
			}
		}
		else{
			localStorage.setItem('token', response.token);
			window.location.href = 'home';
		}
  }
	linkedUserLogin(email: string): Observable<any> {
		return this.http.post(this.apiUrl + '/users/linkedUserLogin', {email: email});
	}
	collaboratorLogin(email: string): Observable<any> {
		return this.http.post(this.apiUrl + '/users/collaboratorLogin', {email: email});
	}
  authorize(isAdmin=false): Promise<boolean | undefined> {
		let token = localStorage.getItem('token');
		if(token){
			const tokenArray = this.parseJwt(token);
			this.token.next(tokenArray);
			this.isAdmin.next(tokenArray.isAdmin);
			this.isLoggedIn.next(true);
		}
		if(isAdmin){
			return of(this.isAdmin.getValue()).toPromise();
		}
		else{
			return of(this.isLoggedIn.getValue()).toPromise();
		}
  }
  refreshToken(){
	this.http.get(this.apiUrl + '/users/token').subscribe((response: any) => {
		if(response.token){
			const tokenArray = this.parseJwt(response.token);
			console.log(tokenArray);
			this.token.next(tokenArray);
		}
	});
  }

	parseJwt (token: any): any {
		let base64Url = token.split('.')[1];
		let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	}

  logout(): Promise<boolean | undefined> {
		localStorage.removeItem('token');
		this.isLoggedIn.next(false);
		this.token.next('');
		this.userSubject.next({});
		return of(this.isLoggedIn.getValue()).toPromise();
  }
}
