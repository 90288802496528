import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from "@angular/material/sort";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserReportService } from '../services/user-reports.service';
import { ShowsService } from '../services/shows.service';
import {environment} from "../../environments/environment";
import {Show} from "../interfaces/show.interface";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { fromEvent, pipe } from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrl: './user-report.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserReportComponent implements OnInit {
  displayedColumns: string[] = ['email', 'total_shows', 'total_brands', 'total_collaborators', 'downloads', 'listeners', 'feedbacks', 'latest_listen', 'expand'];
  dataSource = new MatTableDataSource();

  website_id: number = -1;
  email: any;
  s: string = '';
  platforms:any = [];
  loading = false;
  processing = false;
  expandedElement: any | null = null; // Track the expanded row
  total_reports = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('search') search!: ElementRef;
  show: any;
  show_id: any;


  constructor(public userReportService: UserReportService, public showsService: ShowsService) {
    const staticPlatforms = [
			{ id: 0 , title: "Pyrocast" },
			{ id: 1 , title: "Skystream" },
			{ id: 2 , title: "Tune2" }
		];
    for(let platform of staticPlatforms){
      this.platforms.push({
        id: platform.id,
        title: platform.title,
      });
    }
  }
  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel="Shows per page:";
    this.dataSource.paginator = this.paginator;
    fromEvent(this.search.nativeElement,'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
        })
      )
  }
  ngOnInit(): void {
    this.getReport();
  }
  getReport() {
    this.loading = true;
    this.userReportService.getUserReport(this.email, this.website_id).subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response.map((data: any) => ({
        ...data,
        childData: []
      })));
      this.dataSource.paginator = this.paginator;
  
      this.total_reports = this.dataSource.filteredData.length;
      this.loading = false;
    });
  }

  fetchChildData(data: any) {
    return [
      { column1: 'Child Data 1', column2: 'Detail 1' },
      { column1: 'Child Data 2', column2: 'Detail 2' }
    ];
  }

  toggleRow(element: any) {
    this.expandedElement = this.expandedElement === element ? null : element;
    if(this.expandedElement){
      element.childData = [];
      this.userReportService.getUserReportDetails(element.email, this.website_id).subscribe((response: any) => {
        element.childData = response
      });
    }
    
  }

  downloadReport(show: any) {
    if (!this.processing) {
      this.processing = true;
      this.showsService.getShowReport(show.show_id).subscribe((response: any) => {
        this.processing = false;
        new AngularCsv(response, show.custom_name);
			});
      
    }
  }
}