import { NgModule } from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, Title} from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { LoginComponent } from './login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { LayoutComponent } from './layout/layout.component';
import { DemoMaterialModule } from './material-module';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { httpInterceptorProviders } from "./http-interceptors";
import { DeletePopupComponent } from './common/delete-popup/delete-popup.component';
import { ErrorPopupComponent } from './common/error-popup/error-popup.component';
import { ConfirmPopupComponent } from './common/confirm-popup/confirm-popup.component';
import { RegisterComponent } from './register/register.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { FieldEditorPopupComponent } from "./common/field-editor-popup/field-editor-popup.component";
import { MyShowComponent } from "./my-show/my-show.component";
import { FileUploadPopupComponent } from "./common/file-upload-popup/file-upload-popup.component";
import { AlertComponent } from './alert/alert.component';
import { AddShowComponent } from './my-show/add-show/add-show.component';
import { AudioPreviewPopupComponent } from "./common/audio-preview-popup/audio-preview-popup.component";
import { DatePipe } from "@angular/common";
import { LoadingComponent } from './common/loading/loading.component';
import { SettingsComponent } from './settings/settings.component';
import { AlertPopupComponent } from './common/alert-popup/alert-popup.component';
import { InactivePopupComponent } from "./common/inactive-popup/inactive-popup.component";

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { FirestoreModule } from '@angular/fire/firestore';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ServiceWorkerModule } from '@angular/service-worker';
import { MessagingService } from "./services/messaging.service";
import { MySingleShowComponent } from './common/my-single-show/my-single-show.component';
import { SingleShowComponent } from './single-show/single-show.component';
import { BrandsComponent } from './brands/brands.component';
import { AddBrandComponent } from './brands/add-brand/add-brand.component';
import { EditBrandComponent } from './brands/edit-brand/edit-brand.component';
initializeApp(environment.firebase);

// ColorPicker
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

// DateTimePicker
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

// Font Picker
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';

// Image Cropper
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditBrandNameComponent } from './brands/edit-brand-name/edit-brand-name.component';
import {LyButtonModule} from "@alyle/ui/button";
import {LyImageCropperModule} from "@alyle/ui/image-cropper";
import {LyToolbarModule} from "@alyle/ui/toolbar";
import {LY_THEME, LY_THEME_NAME, LyTheme2, StyleRenderer, LyHammerGestureConfig} from "@alyle/ui";
import {MinimaDark, MinimaLight} from "@alyle/ui/themes/minima";
import {LySliderModule} from '@alyle/ui/slider';
import { BannerEditorComponent } from './brands/edit-brand/banner-editor/banner-editor.component';
import {LyIconModule} from "@alyle/ui/icon";
import { PreviewBrandComponent } from './my-show/preview-brand/preview-brand.component';
import { AnnouncementsComponent } from './common/announcements/announcements.component';
import { QrcodeComponent } from './common/qrcode/qrcode.component';
import { AppLinkComponent } from './common/app-link/app-link.component';
import { LinkedUsersComponent } from './linked-users/linked-users.component';
import { AddEditLinkedUsersComponent } from './linked-users/add-edit-linked-users/add-edit-linked-users.component';
import { VideoUploadPopupComponent } from './common/video-upload-popup/video-upload-popup.component';
import { VideoPreviewPopupComponent } from './common/video-preview-popup/video-preview-popup.component';
import { SecurityCodeComponent } from './common/security-code/security-code.component';
import { ShowReportingComponent } from './common/show-reporting/show-reporting.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { RepushPopupComponent } from './common/repush-popup/repush-popup.component';
import { FingerprintReportComponent } from './fingerprint-report/fingerprint-report.component'
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { NotificationsComponent } from './notifications/notifications.component';
import { AddNotificationComponent } from './notifications/add-notification/add-notification.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InstallShowComponent } from './install-show/install-show.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { CollaboratorAddEditComponent } from './collaborators/collaborator-add-edit/collaborator-add-edit.component';
import { UserReportComponent } from './user-report/user-report.component';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: 'AIzaSyDeA7JIOO07hEHgYshtdOsOQ5h5o0peSP4'
};

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        LayoutComponent,
        DeletePopupComponent,
        ErrorPopupComponent,
        ConfirmPopupComponent,
        InactivePopupComponent,
        RegisterComponent,
        MyShowComponent,
        FieldEditorPopupComponent,
        FileUploadPopupComponent,
        AudioPreviewPopupComponent,
        AlertComponent,
        AddShowComponent,
        LoadingComponent,
        SettingsComponent,
        AlertPopupComponent,
        MySingleShowComponent,
        SingleShowComponent,
        BrandsComponent,
        AddBrandComponent,
        EditBrandComponent,
        EditBrandNameComponent,
        BannerEditorComponent,
        PreviewBrandComponent,
        AnnouncementsComponent,
        QrcodeComponent,
        AppLinkComponent,
        LinkedUsersComponent,
        AddEditLinkedUsersComponent,
        VideoUploadPopupComponent,
        VideoPreviewPopupComponent,
        SecurityCodeComponent,
        ShowReportingComponent,
        DownloadAppComponent,
        RepushPopupComponent,
        FingerprintReportComponent,
        NotificationsComponent,
        AddNotificationComponent,
        InstallShowComponent,
        CollaboratorsComponent,
        CollaboratorAddEditComponent,
        UserReportComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatFormFieldModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        LayoutModule,
        DemoMaterialModule,
        MatTableModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        SocialLoginModule,
        NgxMatColorPickerModule,
        FontPickerModule,
        AngularFireModule.initializeApp(environment.firebase, 'skyjams'),
        FirestoreModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatNativeDateModule,
        ImageCropperModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        LyButtonModule,
        LyToolbarModule,
        LyImageCropperModule,
        LySliderModule,
        HammerModule,
        LyIconModule,
        AngularEditorModule], providers: [
        httpInterceptorProviders,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('480464155512-i1nqolb18t28oi0uq3lvp1bc434d8a3v.apps.googleusercontent.com')
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        MessagingService,
        DatePipe,
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        { provide: FONT_PICKER_CONFIG, useValue: DEFAULT_FONT_PICKER_CONFIG },
        [LyTheme2],
        [StyleRenderer],
        // Theme that will be applied to this module
        { provide: LY_THEME_NAME, useValue: 'minima-light' },
        { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
        { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
        // Gestures
        { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>,
        Title,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
