import { CollaboratorsService } from './../services/collaborators.service';
import { Collaborators } from './../interfaces/collaborators';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {AuthService} from "../services/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {LoadingComponent} from "../common/loading/loading.component";
import { ConfirmPopupComponent } from '../common/confirm-popup/confirm-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollaboratorAddEditComponent } from './collaborator-add-edit/collaborator-add-edit.component';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss']
})
export class CollaboratorsComponent implements OnInit {

  displayedColumns: string[] = ['email', 'action'];
	dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
	s: string = '';
	total_collaborators = 0;
	status = [];
	loading = false;
	siteName = 'Pyrocast';
	isPyrocast = true;
	isTune2 = true;

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort)sort!: MatSort;
	@ViewChild('search') search!: ElementRef;

	ngAfterViewInit() {
		this.paginator._intl.itemsPerPageLabel="Collaborators per page:";
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		fromEvent(this.search.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.updateFilters();
				})
			)
			.subscribe();
	}

	updateFilters() {
		this.dataSource.filter = JSON.stringify({ s: this.s });
	}

	collaboratorsFilter() {
		let filterFunction = function (data: any, filter: string): boolean {
			let filters = JSON.parse(filter);
			let match = true;
			return match && ( data.email && data.email.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1 ||
				data.email && data.email.toString().toLowerCase().trim().indexOf(filters.s.toLowerCase()) !== -1);
		}
		return filterFunction
	}

	constructor(public collaboratorsService: CollaboratorsService,
							public auth: AuthService,
							public dialog: MatDialog,
							private cdr: ChangeDetectorRef,
							public _snackBar: MatSnackBar) {
								if(window.location.hostname == environment.skyDomain){
									this.siteName = 'Skystream';
									this.isPyrocast = false;
								}
								else if(window.location.hostname == environment.tune2Domain){
									this.siteName = 'Tune2';
									this.isPyrocast = false;
									this.isTune2 = true;
								}
								this.loadData();
		
	}
	loadData(newCollaborators:any = null) {
		this.loading = true;
		this.dataSource.data = [];
		this.collaboratorsService.index({}, 'email', 'desc', 1, 1000).subscribe(data => {
			this.loading = false;
			data.map( (collaborators: Collaborators) => {
				return collaborators;
			})
			this.dataSource.data = data;
			this.total_collaborators = this.dataSource.filteredData.length
		}, error => {
			if(error.error.error.code){
				this.auth.logout().then(status => {
					window.location.href = environment.appUrl;
				});
			}
		})
	}

	addEdit(collaborators: any) {
		const dialogRef = this.dialog.open(CollaboratorAddEditComponent,{
			id: 'add-collaborators',
			maxWidth: '600px',
			width: '100%',
			data: {
				collaborators: collaborators
			}
		});
		dialogRef.afterClosed().subscribe((collaborators: any) => {
			this.loadData();
		})
	}


	// edit(collaborators: any) {
	// 	const dialogRef = this.dialog.open(CollaboratorAddEditComponent, {
	// 		id: 'edit-collaborators',
	// 		maxWidth: '30px',
	// 		width: '10%',
	// 		data: {
	// 			collaborators: collaborators
	// 		}
	// 	});
	// 	dialogRef.afterClosed().subscribe((collaborators: any) => {
	// 		this.loadData();
	// 	});
	// }


	delete(id: number, email: string) {
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			id: 'delete-collaborators',
			maxWidth: '480px',
			width: '100%',
			data: {
				title: 'Confirmation',
				message: `Are you sure you want to delete ${email} as a collaborator?`
			}
		});

		dialogRef.componentInstance.onConfirm.subscribe(() => {
			this.collaboratorsService.destroy(id).subscribe(() => {
				dialogRef.close()
				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		this.dataSource.filterPredicate = this.collaboratorsFilter();
	}


}
