<div class="content">
  <div class="my-show-table">
    <div class="finger-head">
      <mat-form-field class="table-search">
        <mat-label>Search by Email</mat-label>
        <input matInput placeholder="Search by Email" #search [(ngModel)]="s">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Choose Platform</mat-label>
        <mat-select disableRipple [(value)]="website_id">
          <mat-option [value]="-1">All Platforms</mat-option>
          <mat-option *ngFor="let platform of platforms" [value]="platform.id">{{ platform.title }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="getReport()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Submit</span></button>
    </div>

    <div class="mat-elevation-z8 ex">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email Address </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Total Shows Column -->
        <ng-container matColumnDef="total_shows">
          <th mat-header-cell *matHeaderCellDef> # of Shows </th>
          <td mat-cell *matCellDef="let element"> {{element.total_shows}} </td>
        </ng-container>

        <!-- Total Brands Column -->
        <ng-container matColumnDef="total_brands">
          <th mat-header-cell *matHeaderCellDef> # of Brands </th>
          <td mat-cell *matCellDef="let element"> {{element.total_brands}} </td>
        </ng-container>

        <!-- Collaborators Column -->
        <ng-container matColumnDef="total_collaborators">
          <th mat-header-cell *matHeaderCellDef> # of Collaborators </th>
          <td mat-cell *matCellDef="let element"> {{element.total_collaborators}} </td>
        </ng-container>

        <!-- Downloads Column -->
        <ng-container matColumnDef="downloads">
          <th mat-header-cell *matHeaderCellDef> # Downloads </th>
          <td mat-cell *matCellDef="let element"> {{element.downloads}} </td>
        </ng-container>

        <!-- Listeners Column -->
        <ng-container matColumnDef="listeners">
          <th mat-header-cell *matHeaderCellDef> # Listens </th>
          <td mat-cell *matCellDef="let element"> {{element.listeners}} </td>
        </ng-container>

        <!-- Feedback Column -->
        <ng-container matColumnDef="feedbacks">
          <th mat-header-cell *matHeaderCellDef> # Feedback </th>
          <td mat-cell *matCellDef="let element"> {{element.feedbacks}} </td>
        </ng-container>

        <!-- Latest Listen Column -->
        <ng-container matColumnDef="latest_listen">
          <th mat-header-cell *matHeaderCellDef> Latest Listen </th>
          <td mat-cell *matCellDef="let element"> {{element.latest_listen}} </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="toggleRow(element)">
              <mat-icon>
                {{ expandedElement === element ? 'expand_less' : 'expand_more' }}
              </mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell class="details-td" *matCellDef="let element" [attr.colspan]="displayedColumns.length + 1">
            <div class="detail-row-table" *ngIf="expandedElement === element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div style="text-align: center" *ngIf="element.childData.length == 0">Loading...</div>
              <table class="child-table" *ngIf="element.childData.length > 0">
                <thead>
                  <tr>
                    <th mat-header-cell>Show Name</th>
                    <th mat-header-cell>Show Date</th>
                    <th mat-header-cell># Listens</th>
                    <th mat-header-cell># Downloads</th>
                    <th mat-header-cell># Feedback</th>
                    <th width="7%" mat-header-cell>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let child of element.childData">
                    <td mat-cell>{{ child.custom_name }}</td>
                    <td mat-cell>{{ child.show_date | date }}</td>
                    <td mat-cell>{{ child.listeners }}</td>
                    <td mat-cell>{{ child.downloads }}</td>
                    <td mat-cell>{{ child.feedbacks }}</td>
                    <td mat-cell><button mat-icon-button (click)="downloadReport(child)"><mat-icon>{{'arrow_downward'}}</mat-icon></button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </ng-container>

        <!-- Render the rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></tr>

        <!-- Expanded row -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td colspan="8" class="mat-cell">{{ loading ? 'Loading...' : 'No records found.' }}</td>
        </tr>

      </table>
      <mat-paginator [class]="total_reports <= 50 ? 'hidden_dropdown':''" [pageSizeOptions]="[50, 100, 250, 500]" aria-label="Select page of the records."></mat-paginator>
  </div>
</div>