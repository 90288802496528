import { LinkedUsersComponent } from './linked-users/linked-users.component';
import { FingerprintReportComponent } from './fingerprint-report/fingerprint-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MyShowComponent } from './my-show/my-show.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from "./guards/guest.guard";
import {AudioPreviewPopupComponent} from "./common/audio-preview-popup/audio-preview-popup.component";
import { SingleShowComponent } from './single-show/single-show.component';
import {BrandsComponent} from "./brands/brands.component";
import {AdminGuard} from "./guards/admin.guard";
import { NotificationsComponent } from './notifications/notifications.component';
import { InstallShowComponent } from './install-show/install-show.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { UserReportComponent } from './user-report/user-report.component';
const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    data: {fullPageTemplate: true},
    canActivate: [GuestGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {fullPageTemplate: true},
    canActivate: [GuestGuard]
  },
  {
    path: 'home',
    component: MyShowComponent,
		canActivate: [AuthGuard]
  },
	{
		path: 'brands',
		component: BrandsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'linked-users',
		component: LinkedUsersComponent,
		canActivate: [AdminGuard]
  },
	{
		path: 'pro-reporting',
		component: FingerprintReportComponent,
		canActivate: [AdminGuard]
  },
  {
		path: 'notifications',
		component: NotificationsComponent,
		canActivate: [AdminGuard]
  },
  {
		path: 'download-app',
		component: DownloadAppComponent,
		canActivate: [AuthGuard]
  },
	{
		path: 'collaborator',
		component: CollaboratorsComponent,
		canActivate: [AuthGuard]
  },
	{
		path: 'user-reporting',
		component: UserReportComponent,
		canActivate: [AuthGuard]
  },
  {
		path: 'shows',
		component: InstallShowComponent,
    data: {fullPageTemplate: true},
	},
	{
		path: ':show_code',
		component: SingleShowComponent,
    data: {fullPageTemplate: true},
	},
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: '/login', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
