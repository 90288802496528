import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
	processing = false;
	adjustment = 0;
	isPyrocast = true;
	siteName = 'Pyrocast';
  constructor(private dialogRef: MatDialogRef<SettingsComponent>) {
		if(window.localStorage.getItem('adjustment')){
			this.adjustment = Number(window.localStorage.getItem('adjustment'));
		}
		if(window.location.hostname == environment.skyDomain){
			this.isPyrocast = false;
			this.siteName = 'SkyStreamEvents';
		}
		else if(window.location.hostname == environment.tune2Domain){
			this.isPyrocast = false;
			this.siteName = 'Tune2';
		}
	}

  ngOnInit(): void {
  }

	save(){
		window.localStorage.setItem('adjustment', this.adjustment + '')
		this.dialogRef.close(false);
	}

	cancel() {
		this.dialogRef.close(false);
	}

	adjust(add: boolean){
		this.adjustment += (add ? 1 : -1) * 0.1;
	}
}
