import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ShowsService} from "../services/shows.service";
import {DatePipe} from "@angular/common";
import {DatabaseService} from "../services/database.service";
import { ActivatedRoute } from '@angular/router';
import { Show } from '../interfaces/show.interface';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-install-show',
  templateUrl: './install-show.component.html',
  styleUrls: ['./install-show.component.scss']
})
export class InstallShowComponent implements OnInit, AfterViewInit {

  public loaded = false;
  public show!:Show;
  show_code: any;
	qrcodeUrl = ''
	env: any;
  isPyrocast = true
  isTune2 = false;
  logo = '';
  siteName = 'Pyrocast'
  iosApp = 'https://apps.apple.com/app/pyrocast/id1583221898';
  androidApp = 'https://play.google.com/store/apps/details?id=com.cobra.audioApp&pli=1';
  email = '';

  constructor(
							private showService: ShowsService,
							private cdr: ChangeDetectorRef,
							public datepipe: DatePipe,
							private db: DatabaseService,
              private route: ActivatedRoute) {
				if(window.location.hostname == environment.skyDomain){
          this.isPyrocast = false;
          this.logo = 'skystream_logo.png';
          this.siteName = 'Skystream Events'
          this.iosApp = 'https://apps.apple.com/app/skystream-events/id6443556051';
          this.androidApp = 'https://play.google.com/store/apps/details?id=com.sky.stream&hl=en&gl=US';
          this.email = 'help@skystreamevents.com';
        }
        else if(window.location.hostname == environment.tune2Domain){
          this.isPyrocast = false;
          this.isTune2 = true;
          this.siteName = 'Tune2'
          this.logo = 'tune2-logo.webp';
          this.iosApp = 'https://apps.apple.com/app/tune2/id6547844420';
          this.androidApp = 'https://play.google.com/store/apps/details?id=com.tune.two';
          this.email = 'help@tune2.app';
        }
        else{
          this.logo = 'logo-sm.png';
          this.email = 'help@pyrocast.com'
        }
	}

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params['showid']){
        this.show_code = params['showid'];
        this.showService.getSingleShow( this.show_code ).subscribe((response:any) => {
          if(response) {
			this.show = response;
			this.showService.getAppUrl(this.show).subscribe((response:any) => {
				if(response.status && response.status === 'success'){
					this.qrcodeUrl = 'https://qrcode.tec-it.com/API/QRCode?data=' + encodeURIComponent(response.shortLink) + '&backcolor=%23ffffff&method=download';
				}
			});
          }
        }, (error) => {
			this.loaded = true;
		});
      }
    });
  }

	ngOnDestroy() {
	}
}
